export default function (
  amount?: number,
  { minimumFractionDigits = 2, maximumFractionDigits = 2, ...opts }: Intl.NumberFormatOptions = {}
) {
  const { locale } = useI18n()
  const currency = useCurrency()

  return amount?.toLocaleString(locale.value, {
    style: 'currency',
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
    ...opts
  })
}
