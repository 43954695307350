import useCountryCode from './useCountryCode'
import { IMarketCurrencies } from '~/types/feeder'
import { CountryCode } from '~/types/locales'
import { DEFAULT_FIAT } from '~/data/cryptoMarket'

export default function () {
  const countryCode = useCountryCode()

  if (countryCode === CountryCode.CH) {
    return 'chf' as keyof IMarketCurrencies
  }

  return DEFAULT_FIAT as keyof IMarketCurrencies
}
